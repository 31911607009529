import { Card, Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import Messages from "../Messages";
import Navbar from "../Navbar";
import UserMessengerEntries from "../UserMessengerEntries";
import { getConversations } from "../../api/messages";
import { isLoggedIn } from "../../helpers/authHelper";
import { useLocation } from "react-router-dom";

const MessengerView = () => {
  const [conservant, setConservant] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [width, setWindowWidth] = useState(window.innerWidth);
  const mobile = width < 800;
  const user = isLoggedIn();
  const { state } = useLocation();
  const newConservant = state?.user || null;

  const getConversation = (conversations, conservantId) => {
    if (!conservantId) return null;

    for (let i = 0; i < conversations.length; i++) {
      const conversation = conversations[i];
      if (conversation.recipient && conversation.recipient._id === conservantId) {
        return conversation;
      }
    }
    return null;
  };

  const fetchConversations = async () => {
    try {
      let conversations = await getConversations(user);
      if (newConservant) {
        setConservant(newConservant);
        if (!getConversation(conversations, newConservant._id)) {
          const newConversation = {
            _id: newConservant._id,
            recipient: newConservant,
            new: true,
            messages: [],
          };
          conversations = [newConversation, ...conversations];
        }
      }
      setConversations(conversations);
    } catch (error) {
      console.error("Failed to fetch conversations:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, [newConservant]);

  useEffect(() => {
    const updateDimensions = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <Container maxWidth="xl">
      <Navbar />
      <Box>
        <Card sx={{ padding: 0 }}>
          <Grid container sx={{ height: "calc(100vh - 110px)" }} alignItems="stretch">
            {!mobile ? (
              <>
                <Grid item xs={5} sx={{ borderRight: 1, borderColor: "divider", height: "100%" }}>
                  <UserMessengerEntries
                    conservant={conservant}
                    conversations={conversations}
                    setConservant={setConservant}
                    loading={loading}
                  />
                </Grid>
                <Grid item xs={7} sx={{ height: "100%" }}>
                  <Messages
                    conservant={conservant}
                    conversations={conversations}
                    setConservant={setConservant}
                    setConversations={setConversations}
                    getConversation={getConversation}
                  />
                </Grid>
              </>
            ) : !conservant ? (
              <Grid item xs={12} sx={{ borderRight: 1, borderColor: "divider", height: "100%" }}>
                <UserMessengerEntries
                  conservant={conservant}
                  conversations={conversations}
                  setConservant={setConservant}
                  loading={loading}
                />
                <Box sx={{ display: "none" }}>
                  <Messages
                    conservant={conservant}
                    conversations={conversations}
                    setConservant={setConservant}
                    setConversations={setConversations}
                    getConversation={getConversation}
                  />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ height: "100%" }}>
                <Messages
                  conservant={conservant}
                  conversations={conversations}
                  setConservant={setConservant}
                  setConversations={setConversations}
                  getConversation={getConversation}
                  mobile
                />
              </Grid>
            )}
          </Grid>
        </Card>
      </Box>
    </Container>
  );
};

export default MessengerView;
